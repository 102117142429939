import React, { useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Text, ActionIcon, Container } from "@mantine/core";
import { checkDatabaseStatus } from "../services/fetch.service";
import { IconRefresh } from "@tabler/icons-react";
import { updateToast } from "../services/toast.service";

function WebSiteDownPage() {
	const navigate = useNavigate();
	const timeoutId = useRef(null);

	const onRefreshClick = useCallback(() => {
		const thisToast = toast.info("Trying to reach database...", {
			toastId: "database-down-toast",
		});

		if (timeoutId.current) {
			clearTimeout(timeoutId.current);
		}

		checkDatabaseStatus().then((databaseStatus) => {
			if (databaseStatus) {
				updateToast(thisToast, "Database is back up!", "success");
				navigate("/login");
			} else {
				toast.update(thisToast, {
					render: "Database is still down. Trying again in 30 seconds",
					type: "warning",
					autoClose: 30 * 1000,
					closeOnClick: true,
				});

				timeoutId.current = setTimeout(() => {
					onRefreshClick();
				}, 31 * 1000);
			}
		});
	}, [navigate]);

	useEffect(() => {
		toast.dismiss({ id: "database-down-toast" });
		onRefreshClick();

		return () => {
			if (timeoutId.current) {
				clearTimeout(timeoutId.current);
			}
		};
	}, [onRefreshClick]);

	return (
		<Container>
			<Box align="center" maw={400} mx="auto">
				<Text align="center" size="xl" weight={500}>
					Database is currently down
				</Text>
				<Text align="center" size="sm" mt={30}>
					We're currently experiencing some issues. Our team is
					working on it. Please contact me if this issue persists.
				</Text>
				<ActionIcon
					variant="filled"
					aria-label="Settings"
					onClick={onRefreshClick}
					mt={20}
				>
					<IconRefresh
						style={{ width: "70%", height: "70%" }}
						stroke={1.5}
					/>
				</ActionIcon>
			</Box>
		</Container>
	);
}

export default WebSiteDownPage;
