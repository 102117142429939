import React, { useState, useRef } from "react";
import {
	Box,
	SimpleGrid,
	Tabs,
	Group,
	Button,
	Anchor,
	Stepper,
	Card,
	Image,
	Text,
	Badge,
	rem,
	Loader,
} from "@mantine/core";
import { fetchFromAPI } from "../services/fetch.service";
import {
	IconUserCircle,
	IconMessage,
	IconMailOpenedFilled,
} from "@tabler/icons-react";

function HomePage() {
	// Set the document title
	document.title = "MooseBoxx - Plex";
	const [pageHeading, setPageHeading] = useState("MooseBoxx");
	const [activeStep, setActiveStep] = useState(0);
	const asOfDateIntervalRef = useRef();
	const [isCurrentCount, setIsCurrentCount] = useState(false);

	const nextStep = () =>
		setActiveStep((current) => {
			if (current === 3) {
				handleContentTabClick();
			} else if (current < 3) {
				if (current === 2) {
					setNextStepButtonText("Available Content");
				}
				return current + 1;
			}
			return current;
		});
	const [nextStepButtonText, setNextStepButtonText] = useState("Next Step");
	const prevStep = () =>
		setActiveStep((current) => {
			if (current > 0) {
				setNextStepButtonText("Next Step");
				return current - 1;
			}
			return current;
		});

	const [requestSiteStatus, setRequestSiteStatus] = useState("PINGING...");
	const [requestSiteStatusColor, setRequestSiteStatusColor] =
		useState("yellow");

	const [tabSelected, setTabSelected] = useState("");

	const [movieCount, setMovieCount] = useState("0");
	const [seriesCount, setSeriesCount] = useState("0");
	const [episodeCount, setEpisodeCount] = useState("0");
	const [asOfDateText, setAsOfDateText] = useState("0");

	const handleAccessTabClick = () => {
		if (asOfDateIntervalRef.current) {
			clearInterval(asOfDateIntervalRef.current);
		}
		setTabSelected("getAccess");
		setActiveStep(0);
		setNextStepButtonText("Next Step");
	};

	const handleContentTabClick = async () => {
		setAsOfDateText("");
		// Clear any existing interval
		if (asOfDateIntervalRef.current) {
			clearInterval(asOfDateIntervalRef.current);
		}
		setPageHeading("MooseBoxx has a ton of content available!");
		setTabSelected("contentAvailable");

		var latest_success = false;

		// Get the latest content counts from the server (database)
		const API_PATH = "/content/counts/latest";
		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (data.status === "success") {
				latest_success = true;
				setMovieCount(data.counts.MovieCount);
				setSeriesCount(data.counts.SeriesCount);
				setEpisodeCount(data.counts.EpisodeCount);
				// Format the date and time to be more readable
				// Example: MM/DD/YYYY at HH:MM:SS
				const date = new Date(data.counts.DateLastUpdated);
				const formattedDate = formatAsOfDate(date);
				setAsOfDateText(formattedDate);
			}
		} catch (error) {}
		if (latest_success) {
			// Sleep for 5 second to allow the database to update
			//await new Promise((resolve) => setTimeout(resolve, 5000));

			// Get the current content counts from the server (tautulli)
			const TAUTULLI_API_PATH = "/content/counts/current";
			try {
				const data = await fetchFromAPI(TAUTULLI_API_PATH, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				});
				if (data.status === "warning") {
					if (
						data.message ===
							"could not get content counts from database" ||
						data.message ===
							"could not update content counts in database"
					) {
						setIsCurrentCount(true);
						setMovieCount(data.counts.MovieCount);
						setSeriesCount(data.counts.SeriesCount);
						setEpisodeCount(data.counts.EpisodeCount);
						setAsOfDateText(
							formatAsOfDate(
								new Date(data.counts.DateLastUpdated)
							)
						);
					}
				} else if (
					data.status === "success" ||
					data.status === "none"
				) {
					setIsCurrentCount(true);
					setMovieCount(data.counts.MovieCount);
					setSeriesCount(data.counts.SeriesCount);
					setEpisodeCount(data.counts.EpisodeCount);

					// Start the interval to update the asOfDateText every second
					asOfDateIntervalRef.current = setInterval(() => {
						const seconds = Math.floor(
							(new Date() -
								new Date(data.counts.DateLastUpdated)) /
								1000
						);
						if (seconds < 30) {
							setAsOfDateText(`As of ${seconds} seconds ago`);
						} else {
							// Use the return value of formatAsOfDate to update asOfDateText
							const formattedDate = formatAsOfDate(
								new Date(data.counts.DateLastUpdated)
							);
							setAsOfDateText(formattedDate);
						}
					}, 1000);

					// Clear the interval when the component unmounts
					return () => asOfDateIntervalRef(asOfDateIntervalRef);
				}
			} catch (error) {}
		}
	};

	const formatAsOfDate = (date) => {
		const formattedDate = `As of: ${
			date.getMonth() + 1
		}/${date.getDate()}/${date.getFullYear()} at ${date.toLocaleTimeString(
			[],
			{ hour: "2-digit", minute: "2-digit" }
		)}`;
		return formattedDate;
	};

	const handleRequestTabClick = async () => {
		if (asOfDateIntervalRef.current) {
			clearInterval(asOfDateIntervalRef.current);
		}

		setPageHeading("Its super easy to request new content!");
		setTabSelected("requestContent");
		setRequestSiteStatus("PINGING...");
		setRequestSiteStatusColor("yellow");
		// Check to see if the requests site is up or down
		const REQUESTS_API_PATH = "/requests/status";
		try {
			const response = await fetchFromAPI(REQUESTS_API_PATH, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.status === "success") {
				setRequestSiteStatus("ONLINE");
				setRequestSiteStatusColor("green");
			} else {
				setRequestSiteStatus("OFFLINE");
				setRequestSiteStatusColor("red");
			}
		} catch (error) {}
	};

	const handleOptimalTabClick = () => {
		if (asOfDateIntervalRef.current) {
			clearInterval(asOfDateIntervalRef.current);
		}
		setTabSelected("optimalSettings");
		setPageHeading("Want to know the optimal settings for your device?");
	};

	const handleRequestNewContentButtonClick = () => {
		window.open("https://requests.mooseboxx.com", "_blank");
	};

	const handleOptimalSettingsButtonClick = (device) => () => {
		window.open(
			`optimal_settings/plex_instructions_${device}.jpeg`,
			"_blank"
		);
	};

	return (
		<Box maw={"700"} mx="auto">
			<Tabs value={tabSelected}>
				<Tabs.List justify="center">
					<Tabs.Tab value="getAccess" onClick={handleAccessTabClick}>
						Get Access to Mooseboxx
					</Tabs.Tab>
					<Tabs.Tab
						value="contentAvailable"
						onClick={handleContentTabClick}
					>
						Content Available
					</Tabs.Tab>
					<Tabs.Tab
						value="requestContent"
						onClick={handleRequestTabClick}
					>
						Request New Content
					</Tabs.Tab>
					<Tabs.Tab
						value="optimalSettings"
						onClick={handleOptimalTabClick}
					>
						Optimal Settings
					</Tabs.Tab>
				</Tabs.List>
			</Tabs>

			<h2>{pageHeading}</h2>
			{tabSelected === "" && (
				<Box justify="space-between">
					<p>
						MooseBoxx is a Plex server that has a ton of content
						available for you to watch. We have a large library of
						both movies and series.
					</p>
					<p>
						Are we missing something that you want to watch? No
						problem! We have a{" "}
						<Anchor
							href="https://requests.mooseboxx.com"
							aria-label="requests-site-link"
						>
							site
						</Anchor>{" "}
						where you can request new content.
					</p>
					<p>
						If you are interested in getting access to the MooseBoxx
						server, click the button below to get detailed
						instructions on how to do so.
					</p>

					<Button
						mt="lg"
						variant="gradient"
						onClick={handleAccessTabClick}
					>
						Get Access to Mooseboxx
					</Button>
				</Box>
			)}

			{tabSelected === "getAccess" && (
				<Box justify="space-between">
					<Stepper
						active={activeStep}
						onStepClick={setActiveStep}
						w={"100%"}
					>
						<Stepper.Step
							icon={
								<IconUserCircle
									style={{ width: rem(18), height: rem(18) }}
								/>
							}
						>
							<h2>Step 1</h2>
							<p>
								Create a{" "}
								<Anchor href="https://www.plex.tv/sign-up/">
									Plex
								</Anchor>{" "}
								account. You can choose to sign-up using your
								Apple, Google, or Facebook account to make it
								easier. You don't need to purchase a Plex Pass
								to use MooseBoxx.
							</p>
						</Stepper.Step>
						<Stepper.Step
							icon={
								<IconMessage
									style={{ width: rem(18), height: rem(18) }}
								/>
							}
						>
							<h2>Step 2</h2>
							<p>
								Go to your{" "}
								<Anchor href="https://app.plex.tv/desktop/#!/settings/account">
									Profile
								</Anchor>{" "}
								in Plex to find your username or email. Send
								your favorite person in the world a text message
								with that information. If you know me you know
								how to reach me 😃
							</p>
						</Stepper.Step>
						<Stepper.Step
							icon={
								<IconMailOpenedFilled
									style={{ width: rem(18), height: rem(18) }}
								/>
							}
						>
							<h2>Step 3</h2>
							<p>
								Wait for me to add your username or email to the
								server. You will receive an email from Plex when
								you have been added. Follow the instructions in
								the email to add the server to your Plex
								account.
							</p>
						</Stepper.Step>

						<Stepper.Completed>
							<h2>All Done!</h2>
							<p>
								You're all set! Download the Plex app on your
								favorite device and start watching!
							</p>
						</Stepper.Completed>
					</Stepper>

					<SimpleGrid
						cols={{
							base: activeStep === 0 ? 1 : 2,
							sm: activeStep === 0 ? 1 : 2,
							lg: activeStep === 0 ? 1 : 2,
						}}
						spacing={{ base: 10, sm: "sm" }}
						verticalSpacing={{ base: "sm", sm: "sm" }}
						mx={"auto"}
						mb={10}
					>
						<Button
							variant="gradient"
							gradient={{
								from: "red.5",
								to: "red.9",
								deg: 45,
							}}
							onClick={prevStep}
							style={{
								visibility: activeStep === 0 && "hidden",
							}}
						>
							Back
						</Button>
						<Button onClick={nextStep} variant="gradient">
							{nextStepButtonText}
						</Button>
					</SimpleGrid>
				</Box>
			)}
			{tabSelected === "contentAvailable" && (
				<Box justify="space-between">
					<SimpleGrid
						cols={{ base: 3, sm: 3, lg: 3 }}
						spacing={{ base: 10, sm: "sm" }}
						verticalSpacing={{ base: "sm", sm: "sm" }}
						mx={"auto"}
						mb={10}
					>
						<Card withBorder shadow="sm" radius="md">
							<Card.Section withBorder inheritPadding py="xs">
								<Group justify="center">
									<Text fw={1000}>Movies</Text>
								</Group>
							</Card.Section>
							<Card.Section inheritPadding py="xs">
								<Group justify="center">
									<Badge color="green">{movieCount}</Badge>
								</Group>
							</Card.Section>
						</Card>
						<Card withBorder shadow="sm" radius="md">
							<Card.Section withBorder inheritPadding py="xs">
								<Group justify="center">
									<Text fw={1000}>Series</Text>
								</Group>
							</Card.Section>
							<Card.Section withBorder inheritPadding py="xs">
								<Group justify="center">
									<Badge color="green">{seriesCount}</Badge>
								</Group>
							</Card.Section>
						</Card>
						<Card withBorder shadow="sm" radius="md">
							<Card.Section withBorder inheritPadding py="xs">
								<Group justify="center">
									<Text fw={1000}>Episodes</Text>
								</Group>
							</Card.Section>
							<Card.Section withBorder inheritPadding py="xs">
								<Group justify="center">
									<Badge color="green">{episodeCount}</Badge>
								</Group>
							</Card.Section>
						</Card>
					</SimpleGrid>
					<Group justify="center">
						{asOfDateText && (
							<Badge color={isCurrentCount ? "green" : "yellow"}>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									{asOfDateText}
									{!isCurrentCount && (
										<Loader
											type="bars"
											color="red"
											size={15}
											style={{ marginLeft: "10px" }}
										/>
									)}
								</div>
							</Badge>
						)}
					</Group>
					<Group justify="center" mt="md">
						<Button
							onClick={handleRequestTabClick}
							variant="gradient"
						>
							Can't find what you're looking for?
						</Button>
					</Group>
				</Box>
			)}
			{tabSelected === "requestContent" && (
				<Box justify="space-between">
					<Card shadow="sm" padding="lg" radius="md" withBorder>
						<Card.Section
							component="a"
							href="https://requests.mooseboxx.com"
						>
							<Image
								src="overseerr_logo.png"
								h={100}
								fit="contain"
								alt="overseerr_logo"
								justify="center"
							/>
						</Card.Section>

						<Group justify="center" mt="md">
							<Text fw={500}>Requesting New Content</Text>
						</Group>
						<Group justify="center" mt="xs" mb="xs">
							<Badge color={requestSiteStatusColor}>
								{requestSiteStatus}
							</Badge>
						</Group>

						<Text size="sm" c="dimmed">
							MooseBoxx has made it super easy to request new
							content. Simply click the button below to go to the
							request site. Once there login with your Plex
							account. You then can search for the content you
							want, and request it. If available, it will be added
							to the server within 10 minutes.
						</Text>

						<Button
							color="blue"
							fullWidth
							mt="md"
							variant="gradient"
							onClick={handleRequestNewContentButtonClick}
						>
							Request New Content
						</Button>
					</Card>
				</Box>
			)}
			{tabSelected === "optimalSettings" && (
				<Box justify="space-between">
					<p>
						We get that not everyone is a tech wizard. So we have
						this page to help you get the best experience possible.
						Click on the button that corresponds to the device that
						you use to watch content on. There you will see a
						detailed guide on what settings to choose.
					</p>
					<SimpleGrid
						cols={{ base: 2, sm: 4, lg: 4 }}
						spacing={{ base: 10, sm: "sm" }}
						verticalSpacing={{ base: "sm", sm: "sm" }}
						mx={"auto"}
						mb={10}
					>
						<Card shadow="sm" withBorder>
							<Card.Section>
								<Image
									src="optimal_settings/ios_android_logo.jpg"
									h={100}
									fit="contain"
									alt="ios_logo"
									justify="center"
								/>
							</Card.Section>
							<Button
								color="blue"
								fullWidth
								mt="md"
								variant="gradient"
								onClick={handleOptimalSettingsButtonClick(
									"ios_android"
								)}
							>
								iOS / Android
							</Button>
						</Card>
						<Card shadow="sm" withBorder>
							<Card.Section>
								<Image
									src="optimal_settings/apple_tv_logo.jpg"
									h={100}
									fit="contain"
									alt="apple_tv_logo"
									justify="center"
								/>
							</Card.Section>
							<Button
								color="blue"
								fullWidth
								mt="md"
								variant="gradient"
								onClick={handleOptimalSettingsButtonClick(
									"apple_tv"
								)}
							>
								Apple TV
							</Button>
						</Card>
						<Card shadow="sm" withBorder>
							<Card.Section>
								<Image
									src="optimal_settings/tv_app_logo.jpeg"
									h={100}
									fit="contain"
									alt="tv_app_logo"
									justify="center"
								/>
							</Card.Section>
							<Button
								color="blue"
								fullWidth
								mt="md"
								variant="gradient"
								onClick={handleOptimalSettingsButtonClick(
									"tvs"
								)}
							>
								TV App
							</Button>
						</Card>
						<Card shadow="sm" withBorder>
							<Card.Section>
								<Image
									src="optimal_settings/browser_logo.jpg"
									h={100}
									fit="contain"
									alt="browser_logo"
									justify="center"
								/>
							</Card.Section>
							<Button
								fullWidth
								mt="md"
								variant="gradient"
								onClick={handleOptimalSettingsButtonClick(
									"web"
								)}
							>
								Web Browser
							</Button>
						</Card>
					</SimpleGrid>
				</Box>
			)}
		</Box>
	);
}

export default HomePage;
