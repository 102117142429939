import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { fetchFromAPI } from "./fetch.service.js";

const logout = async (token) => {
	const API_PATH = "/api/logout";
	try {
		const data = await fetchFromAPI(API_PATH, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (data.status === "success") {
			return data;
		} else {
			return null;
		}
	} catch (error) {
		return null;
	}
};

// Helper function to get a cookie value by name
const getCookie = (name) => {
	return Cookies.get(name);
};

// Helper function to remove a cookie value by name
const removeToken = (name) => {
	Cookies.remove(name);
};

// Validate the token on the server and return the user information
const validateToken = async (token) => {
	const API_PATH = "/api/auth";
	try {
		const data = await fetchFromAPI(API_PATH, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (data.status === "success") {
			const decodedToken = jwtDecode(token);
			return decodedToken;
		} else {
			removeToken("jwt");
			return null;
		}
	} catch (error) {
		removeToken("jwt");
		return null;
	}
};

// Validate the password reset token on the server
const validatePWResetToken = async (token) => {
	const API_PATH = "/verify_reset_token";
	try {
		const data = await fetchFromAPI(API_PATH, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if (data.status === "success") {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};

const AuthService = {
	logout,
	getCookie,
	removeToken,
	validateToken,
	validatePWResetToken,
};

export default AuthService;
