import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Group, Box, PasswordInput, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { toast } from "react-toastify";
import zxcvbn from "zxcvbn";
import { fetchFromAPI } from "../services/fetch.service.js";
import "../App.css";
import AuthService from "../services/auth.service";
import { updateToast } from "../services/toast.service.js";
import { IconPassword } from "@tabler/icons-react";

const PasswordResetPage = () => {
	const [formTitle, setFormTitle] = useState("");
	const [formDescription, setFormDescription] = useState("");
	const [isMounted, setIsMounted] = useState(true);
	const [validToken, setValidToken] = useState(false);
	const formSubmitButtonText = "Reset Password";
	const navigate = useNavigate();

	// Create a form using the useForm hook
	const form = useForm({
		initialValues: {
			password: "",
			confirmPassword: "",
		},
		validateInputOnBlur: true,
		validate: {
			// Password Validation
			password: (value) => {
				// Check to see if the password is empty
				if (!value) {
					return "Password is required";
				}

				// Check to see if the password is less than 8 characters
				if (value.length < 8) {
					return "Password must be at least 8 characters";
				}
				// Check to see if the password is more than 256 characters
				else if (value.length > 256) {
					return "Password must be less than 256 characters";
				}
				// Use zxcvbn to check the password strength
				else if (zxcvbn(value).score < 3) {
					return "Password is too weak";
				}
				// Check to see if the password contains at least 1 lowercase letter
				else if (!value.match(/[a-z]/)) {
					return "Password must contain at least 1 lowercase letter";
				}
				// Check to see if the password contains at least 1 uppercase letter
				else if (!value.match(/[A-Z]/)) {
					return "Password must contain at least 1 uppercase letter";
				}
				// Check to see if the password contains at least 1 number
				else if (!value.match(/[0-9]/)) {
					return "Password must contain at least 1 number";
				}
				// Check to see if the password contains at least 1 special character
				else if (!value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/)) {
					return "Password must contain at least 1 special character";
				}
			},

			// Confirm Password Validation
			confirmPassword: (value) => {
				// Check to see if the confirm password matches the password
				if (value !== form.values.password) {
					return "Confirm Password must match Password";
				}
			},
		},
	});

	useEffect(() => {
		// Set the document title
		document.title = "MooseBoxx | Password Reset";
		// Get the current URL
		const currentUrl = window.location.href;

		// Create a URL object
		const url = new URL(currentUrl);

		// Get the value of 'pw_token' from the query parameters
		const pwToken = url.searchParams.get("pw_token");

		// Call the API to verify the token
		AuthService.validatePWResetToken(pwToken).then((response) => {
			if (isMounted) {
				if (response) {
					// If the token is valid, display the password reset form
					setFormTitle("Reset Password");
					setFormDescription("Enter your new password below.");
					setValidToken(pwToken);
				} else {
					// If the token is invalid, display an error message
					setFormTitle("Token Invalid or Expired");
					setValidToken(false);
				}
			}
		});

		return () => {
			// Cleanup function
			setIsMounted(false);
		};
	}, [navigate, isMounted]);

	const handleFormSubmit = async (values) => {
		const thisToast = toast.info("Attempting to Reset Password", {
			autoClose: 5000,
		});

		// Check to see the form is valid
		if (!form.isValid) {
			return;
		}

		const API_PATH = "/reset_password";
		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "PATCH",
				body: JSON.stringify({
					password: values.password,
					token: validToken,
				}),
			});
			if (data.status === "success") {
				updateToast(
					thisToast,
					"Password Reset Successfully",
					"success"
				);

				navigate("/login");
			} else {
				updateToast(thisToast, "Password Reset Failed", "error");
			}
		} catch (error) {
			updateToast(thisToast, "Password Reset Failed", "error");
		}
	};

	return (
		<Box maw={400} mx="auto">
			<h1>{formTitle}</h1>
			<p>{formDescription}</p>
			<form onSubmit={form.onSubmit(() => handleFormSubmit(form.values))}>
				{validToken && (
					<PasswordInput
						withAsterisk
						leftSection={
							<IconPassword
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						label="Password"
						id="password"
						placeholder="Password"
						{...form.getInputProps("password")}
					/>
				)}

				{validToken && (
					<PasswordInput
						withAsterisk
						leftSection={
							<IconPassword
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						label="Confirm Password"
						id="confirmPassword"
						placeholder="Confirm Password"
						{...form.getInputProps("confirmPassword")}
					/>
				)}

				{validToken && (
					<Group justify="center" mt="md">
						<Button variant="gradient" type="submit">
							{formSubmitButtonText}
						</Button>
					</Group>
				)}
			</form>
		</Box>
	);
};

export default PasswordResetPage;
