import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthService from "../services/auth.service";
import { toast } from "react-toastify";

const withAuth = (ProtectedComponent) => {
	return (props) => {
		const navigate = useNavigate();
		const location = useLocation();
		const [currentUser, setCurrentUser] = useState(null);
		const [token, setToken] = useState(null);
		const [loading, setLoading] = useState(true);

		useEffect(() => {
			const token = AuthService.getCookie("jwt");
			setToken(token);
			if (!token) {
				navigate("/login", { state: { from: location } });
			} else {
				AuthService.validateToken(token)
					.then((decodedToken) => {
						if (!decodedToken) {
							navigate("/login", { state: { from: location } });
						}
						setCurrentUser(decodedToken);
						setLoading(false);
					})
					.catch((error) => {
						toast.error(
							"Your session has expired. Please login again."
						);
						navigate("/login", { state: { from: location } });
					});
			}
		}, [navigate, location]);

		if (loading) {
			return <p>Loading...</p>;
		}

		return (
			currentUser && (
				<ProtectedComponent
					{...props}
					currentUser={currentUser}
					token={token}
				/>
			)
		);
	};
};

export default withAuth;
