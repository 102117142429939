import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";

import {
	ActionIcon,
	Badge,
	Box,
	Button,
	Card,
	Group,
	LoadingOverlay,
	Menu,
	Modal,
	NumberFormatter,
	SimpleGrid,
	Text,
	rem,
	TextInput,
	NumberInput,
	Textarea,
	Checkbox,
	Indicator,
	NativeSelect,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DatePickerInput } from "@mantine/dates";

import {
	IconBellFilled,
	IconDots,
	IconTrash,
	IconUserEdit,
	IconCalendarMinus,
	IconCalendarPlus,
	IconCalendarOff,
	IconCoin,
	IconCoinOff,
	IconScan,
	IconCurrencyDollar,
	IconPlus,
} from "@tabler/icons-react";

import withAuth from "../services/user.service";
import { toast } from "react-toastify";

import classes from "./Modal.module.css";
import { fetchFromAPI } from "../services/fetch.service";
import { updateToast } from "../services/toast.service";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import Barcode from "react-barcode";
import AuthService from "../services/auth.service";
import { checkDatabaseStatus } from "../services/fetch.service";

function TransactionsPage({ currentUser, token }) {
	const navigate = useNavigate();

	// Set the loading state
	const [loading, setLoading] = useState(true);
	// Set the default heading state
	const [defaultHeading, setDefaultHeading] = useState("");
	// Set the transactions state
	const [transactions, setTransactions] = useState([]);

	///////////////////// Modal States ////////////////////////
	// useDisclosure is a hook from @mantine/hooks that allows us to open and close a modal
	const [openedModal, { open, close }] = useDisclosure(false);

	// Set the modal states
	const [modalType, setModalType] = useState("");
	const [modalTitle, setModalTitle] = useState("");
	const [modalContent, setModalContent] = useState("");
	const [modalSubmitText, setModalSubmitText] = useState("");

	///////////////////// Form States  ////////////////////////

	const [notifyChecked, setNotifyChecked] = useState(0);

	// Create a form using the useForm hook
	const form = useForm({
		initialValues: {
			transaction_id: "",
			user_id: "",
			po_number: "",
			item_name: "",
			item_price: "",
			transaction_date: null,
			expiration_date: "",
			plan_length: "",
			serial_number: "",
			notes: "",
			notify_expiry: "",
		},
		validateInputOnBlur: true,
		validate: {
			// Validate the PO Number field
			po_number: (value) => {
				// Check to see if the value is empty
				if (!value) {
					return "PO Number is required";
				}
				// If the form type is new, check to see if the value is the following format:
				// at least 2 digits - at least 2 a-zA-z - any amount of digits
				if (modalType === "new") {
					if (!value.match(/^\d{2,}-[a-zA-Z]{2,}-\d+$/)) {
						return "PO Number must be in the format 123-PO-123456789";
					}
				}
			},

			// Validate the Item Name field
			item_name: (value) => {
				// Check to see if the value is empty
				if (!value) {
					return "Item Name is required";
				}
				// If length is greater than 256, return an error
				if (value.length > 256) {
					return "Item Name must be less than 256 characters";
				}
			},

			// Validate the Item Price field
			item_price: (value) => {
				// Check to see if the value is empty
				if (!value) {
					return "Item Price is required";
				}
				// If the value is less than 0, return an error
				if (value < 0) {
					return "Item Price must be greater than 0";
				}
				// If the value is greater than 99999.99, return an error
				if (value > 99999.99) {
					return "Item Price must be less than 99999.99";
				}
			},

			// Validate the Transaction Date field
			transaction_date: (value) => {
				// Check to see if the value is empty
				if (!value) {
					return "Transaction Date is required";
				}
				// If the value is greater than today, return an error
				if (value > today) {
					return `Transaction Date must be ${formattedToday} or earlier`;
				}
			},

			// Validate the Plan Length field
			plan_length: (value) => {
				// Check to see if the value is empty
				if (!value) {
					return "Plan Length is required";
				}
			},
		},
	});

	const DaysLeftFormatter = ({ value }) => {
		let daysLeft = calculateDaysLeft(value);
		let suffix = " Days Left";
		if (daysLeft < 0) {
			daysLeft = -daysLeft;
			suffix = " Days Ago";
		}

		return (
			<NumberFormatter
				value={daysLeft}
				suffix={suffix}
				thousandSeparator
			/>
		);
	};

	// Date Functions
	// Get today's date
	const today = new Date();
	// Format the date to be in the format MM/DD/YYYY
	const formattedToday = `${
		today.getMonth() + 1
	}/${today.getDate()}/${today.getFullYear()}`;

	const dayRenderer = (date) => {
		const day = date.getDate();
		const isToday =
			day === today.getDate() &&
			date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear();

		return (
			<Indicator
				size={6}
				color={isToday ? "green" : "default"}
				offset={-5}
			>
				<div>{day}</div>
			</Indicator>
		);
	};

	// Set the sortBy state
	const [sortBy, setSortBy] = useState("expiry");

	// Set the usersList state
	const [usersList, setUsersList] = useState([]);

	// Set the transactionUser state
	const [transactionUser, setTransactionUser] = useState(currentUser.user_id);
	const [transactionUserName, setTransactionUserName] = useState("");

	const onChangeSelectedUser = (value) => {
		setTransactionUser(value);
		const user = usersList.find(
			(user) => String(user.user_id) === String(value)
		);
		if (user) {
			setTransactionUserName(user.name);
		}
	};

	// Handle the user selecting a different sort option from the dropdown
	const handleSortChange = (event) => {
		setSortBy(event.target.value);
	};

	const sortedTransactions = Array.isArray(transactions)
		? transactions.sort((a, b) => {
				switch (sortBy) {
					case "date_new":
						return (
							new Date(b.transaction_date) -
							new Date(a.transaction_date)
						);
					case "date_old":
						return (
							new Date(a.transaction_date) -
							new Date(b.transaction_date)
						);
					case "expiry":
						return (
							new Date(a.expiration_date) -
							new Date(b.expiration_date)
						);
					case "cost_high":
						return b.item_price - a.item_price;
					case "cost_low":
						return a.item_price - b.item_price;
					default:
						return (
							new Date(b.transaction_date) -
							new Date(a.transaction_date)
						);
				}
		  })
		: [];

	// Calculate the number of days left between the date and today
	const calculateDaysLeft = (endDate) => {
		const date1 = new Date(endDate);
		const date2 = new Date(today);
		const differenceInTime = date1.getTime() - date2.getTime();
		const differenceInDays = differenceInTime / (1000 * 3600 * 24);
		return Math.ceil(differenceInDays);
	};

	// Return a color based on number of days left
	const calculateDaysLeftColor = (daysLeft) => {
		// If daysLeft is less than 30, return red
		if (daysLeft <= 45) {
			return "red";
		}
		// Else if daysLeft is less than 365, return yellow
		else if (daysLeft <= 365) {
			return "yellow";
		}
		// Else return green
		else {
			return "green";
		}
	};

	const formatDate = (dateString) => {
		const [year, month, day] = dateString.split("-");
		return `${month}/${day}/${year}`;
	};

	const ClearAllValues = () => {
		form.reset();
		setModalType("");
		setModalTitle("");
		setModalContent("");
		setModalSubmitText("");

		setNotifyChecked(0);
	};

	const [barcodeValue, setBarCodeValue] = useState("");
	const handleGetBarcode = (transaction) => {
		setModalType("barcode");
		setModalTitle("Transaction Barcode");
		setModalContent("Here is the barcode for this transaction.");
		setModalSubmitText("Close");
		setBarCodeValue(transaction.po_number);

		open();
	};

	// Handle the user selecting Edit Transaction from the menu
	const handleNewTransaction = () => {
		form.reset();
		setModalType("new");
		setModalTitle("New Transaction");
		setModalContent("Enter in the information for the new transaction.");
		setModalSubmitText("Add");

		setNotifyChecked(0);

		open();
	};

	// Handle the user selecting Add another item to PO Number from the menu
	const handleNewItemToPO = (transaction) => {
		form.reset();
		setModalType("new");
		setModalTitle("New Transaction");
		setModalContent("Enter in the information for the new transaction.");
		setModalSubmitText("Add");

		setNotifyChecked(0);

		const [year, month, day] = transaction.transaction_date.split("-");
		const localDate = new Date(year, month - 1, day);

		form.setValues({
			po_number: transaction.po_number,
			transaction_date: localDate,
		});
		open();
	};

	// Handle the user selecting Edit Transaction from the menu
	const handleEditTransaction = (transaction) => {
		setModalType("edit");
		setModalTitle("Editing Transaction");
		setModalContent("Please change what you need to and then click Save.");
		setModalSubmitText("Save");

		// Parse the date string manually
		const [year, month, day] = transaction.transaction_date.split("-");
		const localDate = new Date(year, month - 1, day);

		if (transaction.notify_expiry === 0) {
			setNotifyChecked(false);
		}
		if (transaction.notify_expiry === 1) {
			setNotifyChecked(true);
		}

		form.setValues({
			transaction_id: transaction.transaction_id,
			user_id: transaction.user_id,
			po_number: transaction.po_number,
			item_name: transaction.item_name,
			item_price: transaction.item_price,
			transaction_date: localDate,
			plan_length: transaction.plan_length,
			serial_number: transaction.serial_number,
			notes: transaction.notes,
			notify_expiry: transaction.notify_expiry,
		});

		open();
	};

	// Handle the user selecting Delete Transaction from the menu
	const handleDeleteTransaction = (transaction) => {
		setModalType("delete");
		setModalTitle("Delete Transaction");
		setModalContent("Are you sure you want to delete this transaction?");
		setModalSubmitText("Delete");

		form.setValues({
			transaction_id: transaction.transaction_id,
			user_id: transaction.user_id,
			po_number: transaction.po_number,
			item_name: transaction.item_name,
		});

		open();
	};

	// Handle the user selecting Yes from the modal
	const handleYes = async (values) => {
		form.validate(); // validate the form before submitting
		// Check to see if the form is valid
		if ((modalType === "new" || modalType === "edit") && !form.isValid()) {
			return;
		}

		// Uppercase any string in the PO Number field
		values.po_number = values.po_number.toUpperCase();

		setNotifyChecked(values.notify_expiry);

		if (notifyChecked === true) {
			values.notify_expiry = 1;
		} else {
			values.notify_expiry = 0;
		}

		if (modalType === "new") {
			addTransaction(values);
		} else if (modalType === "edit") {
			editTransaction(values);
		} else if (modalType === "delete") {
			deleteTransaction(values);
		}
	};

	// Get the product name from the database
	const GetProductName = async () => {
		const API_PATH = "/mc/sku";
		const body = JSON.stringify({
			sku: form.values.item_name,
		});

		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "POST",
				body: body,
			});
			if (data.status === "success") {
				form.setValues({
					item_name: data.message,
				});
			} else if (data.status === "error") {
				toast.warning("Couldn't find Product Name", {
					autoClose: 1500,
				});
			}
		} catch (error) {
			toast.error("Couldn't find Product Name", {
				autoClose: 1500,
			});
		}
	};

	// Add a new transaction to the database (POST)
	const addTransaction = async (values) => {
		const API_PATH = "/api/mc/transaction/new";
		const body = JSON.stringify({
			po_number: values.po_number,
			item_name: values.item_name,
			item_price: values.item_price,
			transaction_date: values.transaction_date,
			plan_length: values.plan_length,
			serial_number: values.serial_number,
			notes: values.notes,
			notify_expiry: values.notify_expiry,
		});
		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: body,
			});
			if (data.status === "success") {
				toast.success("Transaction Added Successfully", {
					autoClose: 1500,
				});
				close();
				ClearAllValues();
				getTransactions();
			} else if (data.status === "error") {
				toast.error(`Error - Transaction Not Added: ${data.message}`, {
					autoClose: 1500,
				});
			}
		} catch (error) {
			toast.error("Transaction Not Added. Try again later", {
				autoClose: 1500,
			});
		}
	};

	// Edit a transaction in the database (PATCH)
	const editTransaction = async (values) => {
		const thisToast = toast.info("Attempting to edit transaction", {
			autoClose: 5000,
		});

		const API_PATH = "/api/mc/transaction/update";
		const body = JSON.stringify({
			transaction_id: values.transaction_id,
			user_id: values.user_id,
			po_number: values.po_number,
			item_name: values.item_name,
			item_price: values.item_price,
			transaction_date: values.transaction_date,
			plan_length: values.plan_length,
			serial_number: values.serial_number,
			notes: values.notes,
			notify_expiry: values.notify_expiry,
		});

		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "PATCH",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: body,
			});
			if (data.status === "success") {
				updateToast(
					thisToast,
					"Transaction Edited Successfully",
					"success"
				);
				close();
				getTransactions();
			} else if (data.status === "none") {
				updateToast(
					thisToast,
					"Transaction details are the same. No changes made.",
					"warning"
				);
			} else if (data.status === "error") {
				updateToast(
					thisToast,
					`Error Editing Transaction: ${data.message}`,
					"error"
				);
			}
		} catch (error) {
			updateToast(
				thisToast,
				"Error Editing Transaction. Try again later.",
				"error"
			);
		}
	};

	// Delete a transaction in the database (DELETE)
	const deleteTransaction = async (values) => {
		const API_PATH = "/api/mc/transaction/delete";
		const body = JSON.stringify({
			transaction_id: values.transaction_id,
			user_id: values.user_id,
			po_number: values.po_number,
			item_name: values.item_name,
		});

		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: body,
			});
			if (data.status === "success") {
				close();
				getTransactions();
			} else if (data.status === "error") {
				toast.error("Transaction Not Deleted. Please try again later", {
					autoClose: 1500,
				});
			}
		} catch (error) {
			toast.error("Transaction Not Deleted. Try again later", {
				autoClose: 1500,
			});
		}
	};

	// Handle the user searching for a transaction
	const handleSearchChange = (event) => {
		const searchValue = event.target.value;
		if (searchValue === "") {
			getTransactions();
		} else {
			const filteredTransactions = sortedTransactions.filter(
				(transaction) =>
					transaction.item_name
						.toLowerCase()
						.includes(searchValue.toLowerCase())
			);

			if (filteredTransactions.length === 0) {
				getTransactions();
				// Send a toast message saying no transactions were found
				toast.info(`No transactions found matching: '${searchValue}'`, {
					autoClose: 1500,
				});
			} else {
				setTransactions(filteredTransactions);
			}
		}
	};

	const getAddToCalendarDate = (transaction) => {
		const [year, month, day] = transaction.transaction_date.split("-");
		return `${year}-${month}-${day}`;
	};

	useEffect(() => {
		// Set the document title
		document.title = "MooseBoxx | Transactions";

		checkDatabaseStatus().then((databaseStatus) => {
			if (!databaseStatus) {
				// Redirect to the Web Site Down Page
				navigate("/web-site-down");
			}
		});

		const token = AuthService.getCookie("jwt");
		if (token) {
			// Validate the token on the server
			(async () => {
				const decodedToken = await AuthService.validateToken(token);
				if (!decodedToken) {
					// Token exists, redirect to the login page
					navigate("/login");
				}
			})();
		}

		// Query the database to get a list of all users
		// Set the users in state
		async function getUsers() {
			const API_PATH = "/api/users";
			try {
				const data = await fetchFromAPI(API_PATH, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				if (data.status === "success") {
					setUsersList(data.users);
				} else if (data.status === "error") {
					setDefaultHeading("Error Getting Users");
				}
			} catch (error) {
				setDefaultHeading("Error Getting Users");
			}
		}

		if (currentUser.role === "admin") {
			getUsers();
			setTransactionUserName(currentUser.name);
		} else {
			setTransactionUserName(currentUser.name);
		}
		getTransactions();
	}, [navigate, transactionUser, transactionUserName, modalType]);

	async function getTransactions() {
		// Clear the transactions in state
		setTransactions([]);

		// Set the API_PATH to the default
		let API_PATH = "/api/mc/transactions";

		// Check to see if the transactionUser is empty
		if (transactionUser !== "") {
			API_PATH = `/api/mc/transactions?user=${encodeURIComponent(
				transactionUser
			)}`;
		}

		if (
			currentUser.role === "admin" &&
			transactionUser !== currentUser.user_id
		) {
			setTransactionUserName(
				usersList.find((user) => user.user_id === transactionUser).name
			);
		}

		try {
			const data = await fetchFromAPI(API_PATH, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (data.status === "success") {
				setTransactions(data.transactions);
				setDefaultHeading(`${transactionUserName}'s Transactions`);
				if (
					!Array.isArray(data.transactions) ||
					data.transactions.length <= 0
				) {
					setDefaultHeading(
						`No Transactions Found for ${transactionUserName}`
					);
					setLoading(false);
				}
			} else if (data.status === "error") {
				setDefaultHeading("Error Getting Transactions");
			}
		} catch (error) {
			setDefaultHeading("Error Getting Transactions");
		}

		setLoading(false);
	}

	return (
		<Box pb={50}>
			{loading && (
				<LoadingOverlay
					visible={loading}
					zIndex={1000}
					overlayProps={{ radius: "lg", blur: 3 }}
					loaderProps={{ color: "blue", type: "bars" }}
				/>
			)}

			{currentUser && (
				<Box>
					<Group justify="center">
						<h1>{defaultHeading}</h1>
					</Group>
				</Box>
			)}

			{/* If the user is an admin */}
			{currentUser && currentUser.role === "admin" ? (
				<Box mx={50}>
					<NativeSelect
						value={transactionUser}
						label="User"
						onChange={(event) =>
							onChangeSelectedUser(event.currentTarget.value)
						}
						// Get the list of user from the state and map over them as data
						data={usersList.map((user) => {
							return {
								label: user.name,
								value: user.user_id,
							};
						})}
					/>
				</Box>
			) : null}

			{currentUser && (
				<Modal
					opened={openedModal}
					onClose={close}
					title={modalTitle}
					overlayProps={{
						backgroundOpacity: 0.1,
						blur: 1.5,
					}}
					transitionProps={{
						duration: 350,
						timingFunction: "linear",
						transition: "fade",
					}}
					removeScrollProps={{ allowPinchZoom: false }}
				>
					{modalContent}
					<form
						onSubmit={(event) => {
							event.preventDefault(); // prevent the form from refreshing the page
							form.onSubmit(); // validate the form and set any errors
							handleYes(form.values); // call your function with the form values
						}}
					>
						{(modalType === "new" ||
							modalType === "edit" ||
							modalType === "delete") && (
							<TextInput
								label="PO Number"
								placeholder="123-PO-123456789"
								withAsterisk
								{...form.getInputProps("po_number")}
								disabled={modalType === "delete"}
								onChange={(event) => {
									const { value } = event.target;
									const prevValue = form.values.po_number;

									if (
										(value.length === 3 ||
											value.length === 6) &&
										prevValue.length < value.length
									) {
										// Add a dash if the user has typed 3 or 6 characters
										form.setValues({
											po_number: `${value}-`,
										});
									} else if (
										(value.length === 4 ||
											value.length === 7) &&
										prevValue.length > value.length
									) {
										// Remove the dash and the number before it if the user has backspaced on the 4th or 7th position
										form.setValues({
											po_number: value.slice(0, -2),
										});
									} else {
										// Update the value normally
										form.setValues({ po_number: value });
									}
								}}
							/>
						)}

						{(modalType === "new" ||
							modalType === "edit" ||
							modalType === "delete") && (
							<TextInput
								label="Item Name"
								placeholder="Item Name or SKU Number"
								withAsterisk
								{...form.getInputProps("item_name")}
								disabled={modalType === "delete"}
								onBlur={(e) => {
									const value = e.target.value;
									if (/^\d{6}$/.test(value)) {
										GetProductName(); // replace this with the function you want to run
									}
								}}
							/>
						)}
						{(modalType === "new" || modalType === "edit") && (
							<NumberInput
								label="Item Price"
								placeholder="0.00"
								min={0}
								step={0.01}
								allowNegative={false}
								decimalScale={2}
								fixedDecimalScale
								decimalSeparator="."
								withAsterisk
								hideControls
								leftSection={
									<IconCurrencyDollar
										style={{
											width: rem(16),
											height: rem(16),
										}}
									/>
								}
								{...form.getInputProps("item_price")}
							/>
						)}
						{(modalType === "new" || modalType === "edit") && (
							<DatePickerInput
								label="Purchase Date"
								withAsterisk
								valueFormat="MM/DD/YYYY"
								defaultDate={today}
								placeholder="Transaction Date"
								allowDeselect={
									modalType === "new" ? true : false
								}
								clearable={modalType === "new" ? true : false}
								renderDay={dayRenderer}
								{...form.getInputProps("transaction_date")}
							/>
						)}

						{(modalType === "new" || modalType === "edit") && (
							<NativeSelect
								value={form.values.plan_length}
								label="Plan Length"
								placeholder="Plan Length"
								withAsterisk
								{...form.getInputProps("plan_length")}
								onChange={(event) =>
									form.setValues({
										plan_length: event.currentTarget.value,
									})
								}
								// Get the list of user from the state and map over them as data
								data={[
									{
										label: "1 Year",
										value: 1,
									},
									{
										label: "2 Years",
										value: 2,
									},
									{
										label: "3 Years",
										value: 3,
									},
									{
										label: "4 Years",
										value: 4,
									},
								]}
							/>
						)}

						{(modalType === "new" || modalType === "edit") && (
							<TextInput
								label="Serial Number"
								placeholder="Serial Number"
								{...form.getInputProps("serial_number")}
							/>
						)}
						{(modalType === "new" || modalType === "edit") && (
							<Textarea
								label="Notes"
								placeholder="Enter any notes that you want to keep track of here."
								{...form.getInputProps("notes")}
							/>
						)}
						<br />
						{(modalType === "new" || modalType === "edit") && (
							<Checkbox
								classNames={classes}
								label={
									notifyChecked
										? "Notify me when plan is about to expire"
										: "Want to get notified when plan is about to expire?"
								}
								color="green"
								checked={notifyChecked}
								icon={IconBellFilled}
								wrapperProps={{
									onClick: () => setNotifyChecked((c) => !c),
								}}
								{...form.getInputProps("notify_expiry")}
							/>
						)}

						{modalType === "barcode" && (
							<Box mx={"auto"} display="flex" justify="center">
								<Barcode value={barcodeValue} />
							</Box>
						)}

						<SimpleGrid
							cols={{
								base: modalType === "barcode" ? 1 : 2,
								sm: modalType === "barcode" ? 1 : 2,
								lg: modalType === "barcode" ? 1 : 2,
							}}
							spacing={{ base: 10, sm: "xl" }}
							verticalSpacing={{
								base: "xl",
								sm: "xl",
							}}
							style={{ marginTop: "1rem" }}
						>
							<Button
								onClick={close}
								variant="gradient"
								gradient={
									modalType !== "delete" &&
									modalType !== "barcode"
										? {
												from: "red.5",
												to: "red.9",
												deg: 45,
										  }
										: ""
								}
							>
								{modalType === "barcode" ? "Close" : "Cancel"}
							</Button>
							{modalType !== "barcode" && (
								<Button
									type="submit"
									variant="gradient"
									gradient={
										modalType === "delete"
											? {
													from: "red.5",
													to: "red.9",
													deg: 45,
											  }
											: ""
									}
								>
									{modalSubmitText}
								</Button>
							)}
						</SimpleGrid>
					</form>
				</Modal>
			)}

			{/* If current user is not empty and transactions.map is not empty */}
			{currentUser &&
				transactions &&
				Array.isArray(transactions) &&
				transactions.length > 0 && (
					<Box>
						<Box mx={50} mb={10}>
							<NativeSelect
								value={sortBy}
								label="Sort By"
								justify="center"
								onChange={(event) => handleSortChange(event)}
								leftSectionPointerEvents="none"
								// If the sortBy value is date new then show the IconCalendarPlus,
								// else if the sortBy value is date old then show the IconCalendarMinus,
								// else if the sortBy value is expiry then show the IconCalendarOff,
								// else if the sortBy value is cost high then show the IconCoin,
								// else if the sortBy value is cost low then show the IconCoinOff
								leftSection={
									sortBy === "expiry" ? (
										<IconCalendarOff
											style={{
												width: rem(16),
												height: rem(16),
											}}
										/>
									) : sortBy === "date_new" ? (
										<IconCalendarPlus
											style={{
												width: rem(16),
												height: rem(16),
											}}
										/>
									) : sortBy === "date_old" ? (
										<IconCalendarMinus
											style={{
												width: rem(16),
												height: rem(16),
											}}
										/>
									) : sortBy === "cost_high" ? (
										<IconCoin
											style={{
												width: rem(16),
												height: rem(16),
											}}
										/>
									) : sortBy === "cost_low" ? (
										<IconCoinOff
											style={{
												width: rem(16),
												height: rem(16),
											}}
										/>
									) : null
								}
								data={[
									{
										label: "Newest Transactions",
										value: "date_new",
									},
									{
										label: "Oldest Transactions",
										value: "date_old",
									},
									{
										label: "Expiration Date",
										value: "expiry",
									},
									{
										label: "Most Expensive",
										value: "cost_high",
									},
									{
										label: "Least Expensive",
										value: "cost_low",
									},
								]}
							/>
							<TextInput
								label="Search for transactions"
								placeholder="Search"
								onChange={handleSearchChange}
							/>
						</Box>

						<Group>
							<SimpleGrid
								cols={{ base: 1, sm: 2, lg: 3 }}
								spacing={{ base: 10, sm: "sm" }}
								verticalSpacing={{ base: "sm", sm: "sm" }}
								mx={"auto"}
								mb={10}
								mt={10}
							>
								{transactions.map((transaction) => {
									return (
										<Box key={transaction.transaction_id}>
											<Card
												shadow="lg"
												padding="xs"
												radius="lg"
												withBorder
											>
												<Card.Section
													withBorder
													inheritPadding
													py="xs"
												>
													<Group justify="space-between">
														<Text fw={700}>
															{
																transaction.item_name
															}
														</Text>
														<Group justify="right">
															<Menu
																withinPortal
																position="bottom"
																shadow="lg"
																offset={1}
																withArrow
																arrowPosition="center"
															>
																<Menu.Target>
																	<ActionIcon
																		variant="subtle"
																		color="gray"
																	>
																		<IconDots
																			style={{
																				width: rem(
																					16
																				),
																				height: rem(
																					16
																				),
																			}}
																		/>
																	</ActionIcon>
																</Menu.Target>

																<Menu.Dropdown>
																	<Menu.Item
																		leftSection={
																			<IconUserEdit
																				style={{
																					width: rem(
																						14
																					),
																					height: rem(
																						14
																					),
																				}}
																			/>
																		}
																		onClick={() =>
																			handleEditTransaction(
																				transaction
																			)
																		}
																	>
																		Edit
																		Transaction
																	</Menu.Item>

																	<Menu.Item
																		leftSection={
																			<IconScan
																				style={{
																					width: rem(
																						14
																					),
																					height: rem(
																						14
																					),
																				}}
																			/>
																		}
																		onClick={() =>
																			handleGetBarcode(
																				transaction
																			)
																		}
																	>
																		Get
																		Transaction
																		Barcode
																	</Menu.Item>
																	<Menu.Item
																		leftSection={
																			<IconPlus
																				style={{
																					width: rem(
																						14
																					),
																					height: rem(
																						14
																					),
																				}}
																			/>
																		}
																		onClick={() =>
																			handleNewItemToPO(
																				transaction
																			)
																		}
																	>
																		Add
																		another
																		item to
																		PO
																		Number
																	</Menu.Item>
																	<Menu.Item>
																		<AddToCalendarButton
																			label="Add Reminder to Calendar"
																			name={`Plan Expiration: ${transaction.item_name}`}
																			description={`PO Number: ${
																				transaction.po_number
																			}{br}Item Price: $${
																				transaction.item_price
																			}{br}Purchase Date: ${formatDate(
																				transaction.transaction_date
																			)}{br}Plan Length: ${
																				transaction.plan_length
																			} Years{br}Serial Number: ${
																				transaction.serial_number
																			}{br}Notes: ${
																				transaction.notes
																			}`}
																			options="iCal"
																			location={
																				transaction.po_number.startsWith(
																					"171"
																				)
																					? "655 Merrick Ave Westbury, NY 11590"
																					: transaction.po_number.startsWith(
																							"145"
																					  )
																					? "71-43 Kissena Blvd Queens, NY 11367"
																					: "Micro Center"
																			}
																			// Add 1 day to the expiration date so that the reminder is set for the day after the expiration date
																			startDate={getAddToCalendarDate(
																				transaction
																			)}
																			timeZone="America/New_York"
																			lightMode="system"
																			trigger="click"
																			inline
																			listStyle="modal"
																			buttonStyle="date"
																			size="3"
																			hideBranding
																		/>
																	</Menu.Item>

																	<Menu.Divider />
																	<Menu.Label>
																		Danger
																		zone
																	</Menu.Label>
																	<Menu.Item
																		leftSection={
																			<IconTrash
																				style={{
																					width: rem(
																						14
																					),
																					height: rem(
																						14
																					),
																				}}
																			/>
																		}
																		color="red"
																		onClick={() =>
																			handleDeleteTransaction(
																				transaction
																			)
																		}
																	>
																		Delete
																		Transaction
																	</Menu.Item>
																</Menu.Dropdown>
															</Menu>
														</Group>
													</Group>
												</Card.Section>

												<Group
													justify="space-between"
													mt="md"
													mb="xs"
												>
													<Text fw={1000}>
														{transaction.po_number}
													</Text>
													<Badge
														color="green"
														fw={1000}
													>
														<NumberFormatter
															prefix="$ "
															value={
																transaction.item_price
															}
															decimalScale={2}
															fixedDecimalScale
															thousandSeparator
														/>
													</Badge>
												</Group>

												<Group
													justify="space-between"
													mt="xs"
													mb="xs"
												>
													<Text>
														{formatDate(
															transaction.transaction_date
														)}
														{"-"}
														{formatDate(
															transaction.expiration_date
														)}
													</Text>
													<Badge
														color={calculateDaysLeftColor(
															calculateDaysLeft(
																transaction.expiration_date
															)
														)}
														fw={1000}
													>
														<DaysLeftFormatter
															value={
																transaction.expiration_date
															}
														/>
													</Badge>
												</Group>

												<Card.Section
													withBorder
													inheritPadding
													py="xs"
												>
													<Group justify="space-between">
														<Text
															size="sm"
															c="dimmed"
														>
															Serial Number:{" "}
															{transaction.serial_number
																? transaction.serial_number
																: "N/A"}
														</Text>
													</Group>

													<Group
														justify="left"
														mt="xs"
														mb="xs"
													>
														<Text
															size="sm"
															c="dimmed"
														>
															Notes:{" "}
															{transaction.notes
																? transaction.notes
																: "N/A"}
														</Text>
													</Group>
												</Card.Section>
											</Card>
										</Box>
									);
								})}
							</SimpleGrid>
						</Group>
					</Box>
				)}
			<Group justify="center" mt="md" mb="lg">
				<Button onClick={handleNewTransaction} variant="gradient">
					Add New Transaction
				</Button>
			</Group>
		</Box>
	);
}

export default withAuth(TransactionsPage);
