import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import withAuth from "../services/user.service";
import {
	Box,
	Button,
	PasswordInput,
	Tabs,
	TextInput,
	Group,
	rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import zxcvbn from "zxcvbn";
import { toast } from "react-toastify";
import { updateToast } from "../services/toast.service";
import { fetchFromAPI } from "../services/fetch.service";
import {
	IconAt,
	IconPassword,
	IconUser,
	IconUserPlus,
} from "@tabler/icons-react";
import { IconIdBadge } from "@tabler/icons-react";

function ProfilePage({ currentUser, token }) {
	const navigate = useNavigate();
	// Set the document title
	document.title = "MooseBoxx | Profile";

	const [nameDisabled, setNameDisabled] = useState(true);
	const [emailDisabled, setEmailDisabled] = useState(true);
	const [idDisabled, setIdDisabled] = useState(true);
	const [roleDisabled, setRoleDisabled] = useState(true);

	const [oldPasswordDisabled, setOldPasswordDisabled] = useState(true);
	const [newPasswordDisabled, setNewPasswordDisabled] = useState(true);
	const [confirmNewPasswordDisabled, setConfirmNewPasswordDisabled] =
		useState(true);

	const [buttonColor, setButtonColor] = useState("blue");

	const [formType, setFormType] = useState("edit-profile");
	const [editButtonText, setEditButtonText] = useState("Edit Profile");

	const form = useForm({
		initialValues: {
			name: currentUser.name,
			email: currentUser.email,
			user_id: currentUser.user_id,
			role: currentUser.role,
			oldPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		},
		validateInputOnBlur: true,
		validate: {
			name: (value) => {
				if (formType === "save-profile") {
					// Check to see if the name is empty
					if (!value) {
						return "Name is required";
					}
					// Check to see if the name is less than 2 characters
					else if (value.length < 2) {
						return "Name must be at least 2 characters";
					}
					// Check to see if the name is more than 50 characters
					else if (value.length > 50) {
						return "Name must be less than 50 characters";
					}
				}
			},
			email: (value) => {
				if (formType === "save-profile") {
					// Check to see if the email is empty
					if (!value) {
						return "Email is required";
					}
					// Check to see if the email is less than 5 characters
					if (value.length < 5) {
						return "Email must be at least 5 characters";
					}
					// Check to see if the email is more than 50 characters
					else if (value.length > 50) {
						return "Email must be less than 50 characters";
					}
					// Check to see if email matches regex
					else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
						return "Email must be valid";
					}
				}
			},
			user_id: (value) => {
				if (formType === "save-profile") {
					// Check to see if the id is empty
					if (!value) {
						return "ID is required";
					}
					// Check to see if ID is greater than 4 digits
					else if (value.length > 4) {
						return "ID must be 4 digits";
					}
					// Check to see if ID is all numbers
					else if (!String(value).match(/^[0-9]+$/)) {
						return "ID must be all numbers";
					}
				}
			},
			role: (value) => {
				if (formType === "save-profile") {
					// Check to see if the role is empty
					if (!value) {
						return "Role is required";
					}
					// Check to see if role is admin or user
					else if (value !== "admin" && value !== "user") {
						return "Role must be admin or user";
					}
				}
			},
			oldPassword: (value) => {
				if (formType === "save-password") {
					// Check to see if the old password is empty
					if (!value) {
						return "Old Password is required";
					}
				}
			},

			newPassword: (value) => {
				if (formType === "save-password") {
					// Check to see if the new password is empty
					if (!value) {
						return "New Password is required";
					}
					if (value.length < 8) {
						return "Password must be at least 8 characters";
					}
					// Check to see if the password is more than 256 characters
					else if (value.length > 256) {
						return "Password must be less than 256 characters";
					}
					// Use zxcvbn to check the password strength
					else if (zxcvbn(value).score < 3) {
						return "Password is too weak";
					}
					// Check to see if the password contains at least 1 lowercase letter
					else if (!value.match(/[a-z]/)) {
						return "Password must contain at least 1 lowercase letter";
					}
					// Check to see if the password contains at least 1 uppercase letter
					else if (!value.match(/[A-Z]/)) {
						return "Password must contain at least 1 uppercase letter";
					}
					// Check to see if the password contains at least 1 number
					else if (!value.match(/[0-9]/)) {
						return "Password must contain at least 1 number";
					}
					// Check to see if the password contains at least 1 special character
					else if (
						!value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/)
					) {
						return "Password must contain at least 1 special character";
					}
				}
			},
			confirmNewPassword: (value) => {
				if (formType === "save-password") {
					// Check to see if the confirm new password is empty
					if (!value) {
						return "Confirm New Password is required";
					}
					// Check to see if the confirm new password matches the new password
					else if (value !== form.values.newPassword) {
						return "New Password and Confirm New Password fields must match";
					}
				}
			},
		},
	});

	const [formTitle, setFormTitle] = useState("");
	const [formDescription, setFormDescription] = useState("");
	const [formShow, setFormShow] = useState(false);

	// If the user clicks the Profile tab, this function will be called
	const handleProfileTabClick = () => {
		setFormType("edit-profile");
		setNameDisabled(true);
		setEmailDisabled(true);
		setIdDisabled(true);
		setRoleDisabled(true);
		setOldPasswordDisabled(true);
		setNewPasswordDisabled(true);
		setConfirmNewPasswordDisabled(true);

		setEditButtonText("Edit Profile");
		setButtonColor("blue");

		setFormTitle("Change User Profile Details");
		setFormDescription("Enter the new user details below.");
		setFormShow(false);
	};

	// If the user clicks the Change Password tab, this function will be called
	const handlePasswordTabClick = () => {
		setFormType("edit-password");
		setNameDisabled(true);
		setEmailDisabled(true);
		setIdDisabled(true);
		setRoleDisabled(true);
		setOldPasswordDisabled(true);
		setNewPasswordDisabled(true);
		setConfirmNewPasswordDisabled(true);

		setEditButtonText("Edit Password");
		setButtonColor("blue");

		setFormTitle("Change User Password");
		setFormDescription("Enter the new password below.");
		setFormShow(false);
	};

	// If the user clicks the Cancel button, this function will be called
	const handleCancelButtonClick = () => {
		form.reset();
		form.setValues({
			name: currentUser.name,
			email: currentUser.email,
			user_id: currentUser.user_id,
			role: currentUser.role,
			oldPassword: "",
			newPassword: "",
			confirmNewPassword: "",
		});

		setNameDisabled(true);
		setEmailDisabled(true);
		setIdDisabled(true);
		setRoleDisabled(true);
		setOldPasswordDisabled(true);
		setNewPasswordDisabled(true);
		setConfirmNewPasswordDisabled(true);
		setButtonColor("blue");

		if (formType === "save-profile") {
			setEditButtonText("Edit Profile");
			setFormType("edit-profile");
			setFormTitle("Change User Profile Details");
			setFormDescription("Enter the new user details below.");
		}
		if (formType === "save-password") {
			setEditButtonText("Edit Password");
			setFormType("edit-password");
			setFormTitle("Change User Password");
			setFormDescription("Enter the new password below.");
		}
		setFormShow(false);
	};

	// If user clicks the Edit Profile button, this function will be called
	const handleFormSubmit = async (values, formType) => {
		if (formType === "edit-profile") {
			setNameDisabled(false);
			setEmailDisabled(false);
			if (currentUser.role === "admin") {
				setIdDisabled(false);
				setRoleDisabled(false);
			}
			setOldPasswordDisabled(true);
			setNewPasswordDisabled(true);
			setConfirmNewPasswordDisabled(true);

			setEditButtonText("Save Profile");
			setFormType("save-profile");
			setButtonColor("green");

			setFormTitle("Change User Profile Details");
			setFormDescription("Enter the new user details below.");
			setFormShow(true);
		}
		if (formType === "edit-password") {
			setNameDisabled(true);
			setEmailDisabled(true);
			setIdDisabled(true);
			setRoleDisabled(true);
			setOldPasswordDisabled(false);
			setNewPasswordDisabled(false);
			setConfirmNewPasswordDisabled(false);

			setEditButtonText("Save Password");
			setFormType("save-password");
			setButtonColor("green");

			setFormTitle("Change User Password");
			setFormDescription("Enter the new password below.");
			setFormShow(true);
		}
		if (formType === "save-profile") {
			const thisToast = toast.info("Attempting to change password", {
				autoClose: 5000,
			});

			setNameDisabled(true);
			setEmailDisabled(true);
			setIdDisabled(true);
			setRoleDisabled(true);
			setOldPasswordDisabled(true);
			setNewPasswordDisabled(true);
			setConfirmNewPasswordDisabled(true);

			setEditButtonText("Edit Profile");
			setFormType("edit-profile");
			setButtonColor("blue");

			setFormTitle("Change User Profile Details");
			setFormDescription("Enter the new user details below.");
			setFormShow(false);

			form.reset();
			const API_PATH = "/api/update_user";
			try {
				const data = await fetchFromAPI(API_PATH, {
					method: "PATCH",
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						old_id: currentUser.user_id,
						new_id: values.user_id,
						name: values.name,
						email: values.email,
						role: values.role,
					}),
				});

				if (data.status === "success") {
					updateToast(
						thisToast,
						"User details updated successfully. Please sign in again to see the changes.",
						"success"
					);
					const currentToken = AuthService.getCookie("jwt");
					if (currentToken) {
						const response = await AuthService.logout(currentToken);
						if (
							response === null ||
							response.status === "success"
						) {
							AuthService.removeToken("jwt");
							navigate("/login");
						}
					}
				} else if (data.status === "none") {
					updateToast(
						thisToast,
						"User details not updated. No changes made.",
						"warning"
					);
				} else {
					updateToast(thisToast, "User details not changed", "error");
				}
			} catch (error) {
				updateToast(thisToast, "User details not changed", "error");
			}
			form.reset();
		}
		if (formType === "save-password") {
			const thisToast = toast.info("Attempting to change password", {
				autoClose: 5000,
			});

			setNameDisabled(true);
			setEmailDisabled(true);
			setIdDisabled(true);
			setRoleDisabled(true);
			setOldPasswordDisabled(true);
			setNewPasswordDisabled(true);
			setConfirmNewPasswordDisabled(true);

			setEditButtonText("Edit Password");
			setFormType("edit-password");
			setButtonColor("blue");

			setFormTitle("Change User Password");
			setFormDescription("Enter the new password below.");
			setFormShow(false);

			const API_PATH = "/api/update_password";
			try {
				const data = await fetchFromAPI(API_PATH, {
					method: "PATCH",
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						old_password: values.oldPassword,
						new_password: values.newPassword,
					}),
				});

				if (data.status === "success") {
					updateToast(
						thisToast,
						"Password changed successfully. Please sign in again.",
						"success"
					);
					const currentToken = AuthService.getCookie("jwt");
					if (currentToken) {
						const response = await AuthService.logout(currentToken);
						if (
							response === null ||
							response.status === "success"
						) {
							AuthService.removeToken("jwt");
							navigate("/login");
						}
					}
				} else {
					updateToast(thisToast, "Password not changed.", "error");
				}
			} catch (error) {
				updateToast(thisToast, "Password not changed.", "error");
			}
			form.reset();
		}
	};

	// If user clicks the view all transactions button, this function will be called
	const handleViewAllTransactions = () => {
		navigate("/transactions");
	};

	return (
		<Box maw={"600"} mx="auto">
			<Tabs defaultValue="first">
				<Tabs.List justify="space-between">
					<Tabs.Tab value="first" onClick={handleProfileTabClick}>
						{currentUser.name}'s Profile
					</Tabs.Tab>
					<Tabs.Tab value="second" onClick={handlePasswordTabClick}>
						Change Password
					</Tabs.Tab>
				</Tabs.List>
			</Tabs>
			{formShow && (
				<>
					<h2>{formTitle}</h2>
					<p>{formDescription}</p>
				</>
			)}
			<form
				onSubmit={form.onSubmit(() =>
					handleFormSubmit(form.values, formType)
				)}
			>
				{(formType === "edit-profile" ||
					formType === "save-profile") && (
					<TextInput
						label="Name"
						leftSection={
							<IconUser
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						type="text"
						placeholder={currentUser.name}
						{...form.getInputProps("name")}
						disabled={nameDisabled}
					/>
				)}

				{(formType === "edit-profile" ||
					formType === "save-profile") && (
					<TextInput
						label="Email"
						leftSection={
							<IconAt
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						type="text"
						placeholder={currentUser.email}
						{...form.getInputProps("email")}
						disabled={emailDisabled}
					/>
				)}

				{(formType === "edit-profile" ||
					formType === "save-profile") && (
					<TextInput
						label="User ID"
						leftSection={
							<IconIdBadge
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						type="text"
						placeholder={currentUser.user_id}
						{...form.getInputProps("user_id")}
						disabled={idDisabled}
					/>
				)}

				{(formType === "edit-profile" ||
					formType === "save-profile") && (
					<TextInput
						label="Role"
						leftSection={
							<IconUserPlus
								style={{ width: rem(16), height: rem(16) }}
							/>
						}
						type="text"
						placeholder={currentUser.role}
						{...form.getInputProps("role")}
						disabled={roleDisabled}
					/>
				)}

				{(formType === "edit-password" ||
					formType === "save-password") && (
					<>
						<PasswordInput
							withAsterisk
							leftSection={
								<IconPassword
									style={{ width: rem(16), height: rem(16) }}
								/>
							}
							label="Current Password"
							id="oldPassword"
							placeholder="Current Password"
							{...form.getInputProps("oldPassword")}
							disabled={oldPasswordDisabled}
						/>
						<PasswordInput
							withAsterisk
							leftSection={
								<IconPassword
									style={{ width: rem(16), height: rem(16) }}
								/>
							}
							label="New Password"
							id="newPassword"
							placeholder="New Password"
							{...form.getInputProps("newPassword")}
							disabled={newPasswordDisabled}
						/>
						<PasswordInput
							withAsterisk
							leftSection={
								<IconPassword
									style={{ width: rem(16), height: rem(16) }}
								/>
							}
							label="Confirm New Password"
							id="confirmNewPassword"
							placeholder="Confirm New Password"
							{...form.getInputProps("confirmNewPassword")}
							disabled={confirmNewPasswordDisabled}
						/>
					</>
				)}

				<Group justify="center" mt="md">
					{(formType === "save-profile" ||
						formType === "save-password") && (
						<Button
							onClick={handleCancelButtonClick}
							variant="gradient"
							gradient={{
								from: "red.6",
								to: "red.9",
								deg: 45,
							}}
						>
							Cancel
						</Button>
					)}
					<Button
						type="submit"
						color={buttonColor}
						variant="gradient"
					>
						{editButtonText}
					</Button>
				</Group>
			</form>
			<Group justify="center" mt="md">
				<Button onClick={handleViewAllTransactions} variant="gradient">
					View All Transactions
				</Button>
			</Group>
		</Box>
	);
}

export default withAuth(ProfilePage);
