import React, { useEffect } from "react";
import { Box, Text, Container } from "@mantine/core";

function Page404() {
	useEffect(() => {
		// Set the document title
		document.title = "MooseBoxx | Page Not Found";
	}, []);
	// Return a 404 page
	return (
		<Container>
			<Box align="center" maw={400} mx="auto">
				<Text align="center" size="xl" weight={500}>
					404 Not Found
				</Text>
				<Text align="center" size="sm">
					The page you are looking for does not exist or has been
					moved. Please check the URL and try again. If you believe
					this is an error, please contact the site administrator.
				</Text>
			</Box>
		</Container>
	);
}

export default Page404;
